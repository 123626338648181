<template>
  <div
    :class="{ 'no-wrap': noWrap }"
    :style="{ gap, justifyContent: justify }"
    class="flex-row"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    gap: {
      type: String,
      default: "0px",
    },
    justify: {
      type: String,
      default: null,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-row {
  align-items: center;
  display: flex;

  &.no-wrap {
    flex-wrap: nowrap;
  }
}
</style>
